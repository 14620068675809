<template>
  <page-layout ref="layout" :show-print-option="false" class="d-print-none" @refresh="refresh">
    <template #breadcrumbs="{ year }">
      <b-breadcrumb-item :text="`Events - ${year}`" />
      <template v-if="id">
        <b-breadcrumb-item text="Buses" :to="{ name: 'events-buses', params: { id: null } }" />
        <b-breadcrumb-item v-if="selected.bus" :text="selected.bus.name" active />
      </template>
      <template v-else>
        <b-breadcrumb-item text="Buses" active />
      </template>
    </template>

    <template v-if="hasChanges" #actions="{ }" >
      <b-button v-b-tooltip="'Revert Changes'" variant="link" size="sm" class="btn-icon mr-50" @click="resetSelectedBus">
        <b-icon-arrow-counterclockwise />
      </b-button>
      <can do="update" on="events-buses">
        <b-button v-b-tooltip="'Update'" variant="link" size="sm" class="btn-icon" @click="updateBus">
          <b-icon-save2 />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="events-buses">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
      </can>
      <can do="delete" on="events-buses">
        <b-dropdown-item class="table-row-option-delete"
                         @click="$refs.layout.confirmDelete(selected.bus, deleteBus, cascadeConfirmDeleteOptions)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
      <b-dropdown-divider/>
      <b-dropdown-item @click="sidebar = !sidebar">
        <feather-icon icon="SidebarIcon"/>
        <span class="align-middle ml-50">{{ sidebar ? 'Hide' : 'Show' }} Sidebar</span>
      </b-dropdown-item>
    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: buses.loading, desc: 'Loading Chaperones'},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

    <template #modal>

    </template>

    <template #content="{ state }">
      <event-layout :show-sidebar="sidebar" :auto-toggle-sidebar="false">
        <template #sidebar>
          <events-nav title="Buses"
                      :selected-id="id"
                      :items="computedBuses"
                      :filters="buses.filters"
                      :sorting="buses.sorting"
                      @search="(text) => buses.search = text"
                      @sort="(sorting) => buses.sorting = sorting"
                      @select="(item) => selectBus(item)"
                      @deselect="deselectBus">

            <template #item="{ item, emptyTextClass }">
              <span v-if="item.name">{{ item.name }}</span>
              <span v-else :class="emptyTextClass">Unnamed Bus</span>
            </template>

            <template #actions>
              <can do="create" on="events-buses">
                <b-button size="xs" variant="link" class="btn-icon round px-50" @click="addBus">
                  <b-icon-plus />
                </b-button>
              </can>
            </template>

            <template #filters>
              <v-select
                  v-model="buses.filters.hotelId"
                  input-id="filter-hotel"
                  placeholder="Hotel"
                  :options="students.items" label="name"
                  :reduce="option => option.id"
                  :select-on-tab="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="w-100 font-small-3 mt-2"/>
            </template>
          </events-nav>

          <!-- Print Card -->
          <b-card class="bus-roster-card mb-2" body-class="pt-0" :img-src="image" img-alt="Bus" img-top tag="article">
            <b-card-title class="mb-0">Bus Rosters</b-card-title>
            <b-card-text class="mt-50">
              <p class="mb-50">To print all bus rosters, click the button below.</p>
              <span class="font-small-3">For individual bus rosters, please select a bus from the list and click the printer icon to the right of the students tab.</span>
            </b-card-text>
            <b-button block variant="primary" @click="openBusRosterModal('buses-roster')">Print Bus Rosters</b-button>
            <bus-roster-modal id="buses-roster"
                              :buses="buses.items"
                              :events="studentEvents.items" />
          </b-card>
        </template>
        <template #content>
          <b-overlay :show="state.creating || state.saving" :opacity="1" bg-color="white" rounded fixed class="w-100">
            <template #overlay>
              <!-- Creating Bus -->
              <b-card v-if="state.creating" class="mb-0">
                <b-alert show variant="primary" class="mb-50">
                  <div class="d-flex align-items-center mb-0">
                    <b-icon-cloud-upload class="mr-50" size="16"></b-icon-cloud-upload>
                    <b>Creating Bus</b>
                  </div>
                  <p class="font-small-3">Please wait while the data is being created.</p>
                </b-alert>
                <b-progress animated :value="creating.progress.value" :max="creating.progress.max" :variant="creating.progress.variant" height="7px" />
              </b-card>
            </template>
            <!-- Selected Bus -->
            <template v-if="selected.bus" #default>
              <!-- Bus Details -->
              <b-card>
                <validation-observer ref="observer" slim>
                  <form ref="form">
                    <b-row>
                      <!-- Avatar -->
                      <b-col align-self="start" cols="auto">
                        <b-avatar variant="primary" size="45" badge-variant="white">
                          <font-awesome-icon :icon="icon" size="2x"></font-awesome-icon>
                        </b-avatar>
                      </b-col>
                      <!-- Details -->
                      <b-col align-self="center">
                        <validation-observer ref="observer">
                          <b-row>
                            <b-col align-self="start" cols="12" md="6">
                              <validation-provider v-slot="validationContext" vid="name" name="Name" rules="required">
                                <b-form-group label="Name" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                                  <b-input id="name-input"
                                           v-model="selected.bus.name"
                                           :disabled="!state.editing"
                                           :state="getValidationState(validationContext)"/>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col align-self="start" cols="12" md="6">
                              <validation-provider v-slot="validationContext" vid="capacity" name="Capacity" rules="required">
                                <b-form-group label="Capacity" label-for="capacity-input" :invalid-feedback="validationContext.errors[0]">
                                  <b-input id="capacity-input"
                                           v-model="selected.bus.capacity"
                                           type="number" number
                                           :disabled="!state.editing"
                                           :state="getValidationState(validationContext)"/>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </validation-observer>
                      </b-col>
                    </b-row>
                  </form>
                </validation-observer>
              </b-card>

              <b-alert :show="hasChanges" variant="warning" class="d-print-none mb-2" dismissible>
                <b-icon-info-circle-fill font-scale="1"/> <strong class="align-middle ml-50 font-small-3">Unsaved Changes</strong>
                <p class="align-middle font-small-3">
                  Make sure you save before switching to another Bus. Unsaved changes will be lost.
                </p>
              </b-alert>


              <!-- Tabs -->
              <b-tabs v-if="selected.bus.studentEvents" v-model="studentEvents.tab" nav-class="d-flex flex-row justify-content-start">
                <!-- Students -->
                <b-tab title="Students" lazy>

                  <!-- Alerts -->
                  <b-alert :show="atCapacity && state.editing" variant="warning">
                    <b-row>
                      <b-col cols="auto" class="pr-0">
                        <b-icon-info-circle />
                      </b-col>
                      <b-col class="font-small-3">
                        <strong>This bus is at capacity.</strong>
                        <div>Increase the bus capacity to add additional students from the bus, or remove already assigned students.</div>
                      </b-col>
                    </b-row>
                  </b-alert>
                  <b-alert :show="overCapacity" variant="danger">
                    <b-row>
                      <b-col cols="auto" class="pr-0">
                        <feather-icon icon="AlertCircleIcon"/>
                      </b-col>
                      <b-col class="font-small-3">
                        <strong>This bus is over capacity by {{ selected.bus.studentEvents.items.length - selected.bus.capacity }} students.</strong>
                        <div>Saving has been disabled, please increase the bus capacity or remove students from the bus.</div>
                      </b-col>
                    </b-row>
                  </b-alert>
                  <b-alert :show="selected.bus.studentEvents.items.some(studentEvent => studentEvent.status === 'pending')" variant="warning">
                    <b-row>
                      <b-col cols="auto" class="pr-0">
                        <b-icon-exclamation-circle style="width: 14px; height: 14px"/>
                      </b-col>
                      <b-col class="font-small-3">
                        <strong>Unsaved Assignments</strong>
                        <div>Students assigned by grouping need to be saved after the fact.</div>
                      </b-col>
                      <b-col cols="auto" align-self="center">
                        <b-button size="sm" variant="primary" :disabled="overCapacity" @click="saveStudentEvents">
                          Assign Unsaved Students
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-alert>

                  <b-row>
                    <b-col v-if="state.editing" class="col-12 col-xxl-6">
                      <!-- Unassigned Students -->
                      <unassigned-students
                          :students="students"
                          :selected="selected"
                          :hotels="hotels"
                          :ensembles="computedEnsembles"
                          :instruments="instruments"
                          :state="state"
                          :at-capacity="atCapacity"
                          @assign:capacity="assignStudentsToCapacity"
                          @assign:student="assignStudentClick"
                          @assign:students="assignStudentsClick"/>
                    </b-col>
                    <b-col>
                      <!-- Assigned Students -->
                      <assigned-students
                          :selected="selected"
                          :hotels="hotels"
                          :ensembles="computedEnsembles"
                          :instruments="instruments"
                          :state="state"
                          @assign:studentEvent="assignStudentEventClick"
                          @unassign:studentEvent="unassignStudentEventClick"
                          @unassign:studentEvents="unassignStudentEventsClick"/>
                    </b-col>
                  </b-row>
                </b-tab>

                <template #tabs-end>
                  <template v-if="selected.bus.studentEvents.items.length">
                    <b-button variant="link" class="ml-auto" @click="openBusRosterModal('bus-roster')">
                      <b-icon-printer/>
                    </b-button>
                    <bus-roster-modal id="bus-roster"
                                      :buses="[selected.bus]"
                                      :events="selected.bus.studentEvents.items.filter(e => e.busId === selected.bus.id)" />
                  </template>
                </template>
              </b-tabs>
            </template>
          </b-overlay>
        </template>
      </event-layout>
    </template>

    <template #debug>
      <b-row>
        <b-col>
          <debug title="Buses" collapsed>{{ buses }}</debug>
        </b-col>
        <b-col>
          <debug title="Selected Bus" collapsed>{{ selected.bus }}</debug>
        </b-col>
        <b-col>
          <debug title="Students" collapsed>{{ students }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import PageLayout from '@/components/PageLayout.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import Fuse from 'fuse.js';
import ZipInput from '@/components/ZipInput.vue';
import StateInput from '@/components/StateInput.vue';
import vSelect from 'vue-select';
import DateOfBirth from '@/components/DateOfBirth.vue';
import LastModified from '@/components/LastModified.vue';
import {VueMaskDirective} from 'v-mask';
import events from '@/mixins/event.mixin'
import OverlayLoading from '@/components/OverlayLoading.vue';
import {
  getHotels,
  createBus,
  updateBus,
  deleteBus,
  createStudentEvent,
  updateStudentEvent,
  getBuses, getStudentEventsByBusId, getStudentsFromSelections, StudentEvent, EventStudent
} from '@/views/events/service';
import { prefixes, roles, yesNo } from '@/views/events/service/data';
import notify from '@/mixins/notify.mixin';
import SortInput from '@/components/SortInput.vue';
import _ from 'lodash';
import {uuid} from 'vue-uuid';
import BusRosterModal from '@/views/events/buses/BusRosterModal.vue';
import EventsNav from '@/views/events/EventsNav.vue';
import {cascadeConfirmDeleteOptions, cascadeDeleteBus } from '@/graphql/cascade/bus';
import EventLayout from '@/views/events/EventLayout.vue';
import {batchProcess} from '@/graphql/cascade/batch';
import AssignedStudents from '@/views/events/buses/AssignedStudents.vue';
import UnassignedStudents from '@/views/events/buses/UnassignedStudents.vue';

export default {
  name: 'Buses',
  directives: { mask: VueMaskDirective },
  components: {
    PageLayout,
    EventLayout,
    EventsNav,
    AssignedStudents,
    UnassignedStudents,
    BusRosterModal,

    SortInput,
    OverlayLoading,
    LastModified,
    StateInput,
    ZipInput,
    DateOfBirth,
    BCardActions,
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [ events, notify ],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selected: {
        bus: {
          studentEvents: {
            loading: true,
            items: []
          },
        },
        initialBus: null,
        loading: false,
      },
      creating: {
        progress: {
          value: 0,
          max: 1,
          variant: 'primary'
        }
      },
      buses: {
        loading: true,
        items: [],
        search: null,
        filters: null,
        sorting: {
          by: ['name'],
          desc: false,
          drag: false,
          options: [
            { label: 'Name', value: 'name'},
          ]
        },
      },
      studentEvents: {
        loading: true,
        items: [ ],
        tab: 0,
      },
      students: {
        loading: true,
        loaded: false,
        items: []
      },
      hotels: {
        loading: true,
        items: [],
      },
      instruments: {
        loading: true,
        items: [],
      },
      options: {
        roles: roles,
        prefixes: prefixes,
        yesNo: yesNo,
      },
      icon: 'fas fa-bus',
      // eslint-disable-next-line global-require
      image: require('@/assets/images/illustration/storyset-online-document-rafiki.svg'),
      sidebar: true,
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    computedBuses() {
      const { items = [] } = this.buses
      const fuse = new Fuse(items, {
        useExtendedSearch: true,
        keys: [
          'id',
          'name',
        ]
      })
      const query = { $and: [ ] }
      if(this.buses.search) {
        query.$and.push({
          $or: [
            { name: `'${this.buses.search}` },
          ]
        })
      }

      if(query.$and.length) { return fuse.search(query).map(({ item }) => item) }
      return items
    },
    computedEnsembles() {
      const ensembleMap = this.students.items.reduce((acc, student) => {
        acc[student?.selection?.ensemble?.id] = {
          id: student?.selection?.ensemble?.id,
          name: student?.selection?.ensemble?.name,
        };
        return acc;
      }, {});
      return {
        loading: false,
        items: Object.values(ensembleMap).filter(value => value.id).sort((a, b) => a?.name?.localeCompare(b?.name))
      };
    },
    atCapacity() {
      return this.selected?.bus?.studentEvents?.items?.length === this.selected?.bus?.capacity
    },
    overCapacity() {
      return this.selected?.bus?.studentEvents?.items?.length > this.selected?.bus?.capacity
    },
    hasChanges() {
      const { bus, initialBus } = this.selected
      const { name, capacity } = bus ?? {}
      const { name: initialName, capacity: initialCapacity } = initialBus ?? {}
      return JSON.stringify({ name, capacity }) !== JSON.stringify({ name: initialName, capacity: initialCapacity })
    }
  },
  async created() {
    await this.listBuses()
    await this.listStudentsFromSelections()
    await this.listHotels()
    await this.$nextTick(() => {
      if(this.id) this.selectBus(this.buses.items.find(bus => bus.id === this.id))
      else {
        this.selected.bus = null
      }
      setTimeout( () => { this.$refs.layout.state.loading = false }, 500);
    })
  },
  methods: {
    async refresh() {
      this.$refs.layout.state.loading = true
      await this.listBuses()
      this.$refs.layout.state.loading = false
    },

    async listBuses() {
      this.buses.loading = true;
      await getBuses().then(buses => {
        buses.forEach(bus => {
          bus.studentEvents = { loading: true, items: [] }
        })
        this.buses.items = buses;
        this.buses.loading = false;
        this.buses.loaded = true;
      })
    },
    async selectBus(bus) {
      this.selected = { ...this.selected, loading: true, bus: { studentEvents: { items: [], loading: true }}, initial: null };
      if(!bus) {
        await this.$router.push({ name: 'events-buses', params: { id: null } })
      }
      else {
        if(bus?.studentEvents.loading) {
          await getStudentEventsByBusId(bus.id, { includeNotes: true }).then(studentEvents => {
            studentEvents.forEach(studentEvent => {
              studentEvent.status = 'saved'
            })
            bus.studentEvents.items = studentEvents;
            bus.studentEvents.loading = false;
          })
        }
        this.selected.bus = bus;
        this.selected.initialBus = JSON.parse(JSON.stringify(bus));
        this.selected.loading = false;
        if(this.$route.params.id !== bus.id) {
          await this.$router.push({ name: 'events-bus', params: { id: bus.id } })
        }
      }
      this.selected.loading = false;
    },
    async deselectBus() {
      this.selected.bus = null
      this.selected.initialBus = null
      if(this.$route.params.id !== null) {
        await this.$router.push({ name: 'events-buses', params: { id: null } })
      }
    },
    async addBus() {
      this.$refs.layout.state.creating = true
      this.creating.progress.value = 0
      await createBus().then(bus => {
        bus.studentEvents = { loading: true, items: [] }
        this.buses.items.push(bus)
        this.selected.bus = bus
        this.selectBus(bus)
        this.creating.progress.value = 1
        setTimeout( () => {
          this.$refs.layout.state.creating = false
          this.notify({ title: 'Success', text: 'Bus was successfully created', icon: this.icon, variant: 'success' });
        }, 1000);
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Bus failed to create', icon: this.icon, variant: 'danger'});
      })
    },
    async updateBus() {
      const { id, name, capacity } = this.selected.bus
      await updateBus({ id, name, capacity }).then(() => {
        this.updateSelectedBusInitial()
        this.notify({ title: 'Success', text: 'Bus was successfully updated', icon: this.icon, variant: 'success' });
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Bus failed to update', icon: this.icon, variant: 'danger'});
      })
    },
    async deleteBus(bus, swalCallback) {
      try {
        await this.cascadeDeleteBus(bus.id, swalCallback)
        await this.deselectBus()
        this.buses.items = this.buses.items.filter(b => b.id !== bus.id)

        //Update our list of students in case any of them has selected the bus we just deleted.
        this.students.items.filter(student => student.event && student.event.busId === bus.id).forEach(student => {
          student.event.busId = null
        })
        //Update our list of students in case any of them has selected the bus we just deleted.
        this.studentEvents.items.filter(studentEvent => studentEvent.busId === bus.Id).forEach(studentEvent => {
          studentEvent.busId = null
        })
        this.notify({ title: 'Success', text: 'Bus was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Bus failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
    },
    cascadeDeleteBus,
    batchProcess,
    resetSelectedBus() {
      // Deep clone the initialBus object to selected bus.
      this.selected.bus = JSON.parse(JSON.stringify(this.selected.initialBus));

      // Find the index of the bus with the matching id in the buses array
      const index = this.buses.items.findIndex(bus => bus.id === this.selected.bus.id);

      // Replace the bus at the found index with the selected bus
      if (index !== -1) {
        this.buses.items.splice(index, 1, this.selected.bus);
      }
    },
    updateSelectedBusInitial() {
      this.selected.initialBus = JSON.parse(JSON.stringify(this.selected.bus))
    },

    async listHotels() {
      this.hotels.loading = true;
      await getHotels().then(hotels => {
        this.hotels.items = hotels;
        this.hotels.loading = false;
        this.hotels.loaded = true;
      })
    },
    async listStudentsFromSelections() {
      this.students.loading = true;
      await getStudentsFromSelections().then(students => {
        this.students.items = students;
        this.instruments.items = Array.from(new Map(students.map(student => {
          const instrument = student?.selection?.instrument;
          return instrument ? [instrument.id, instrument] : null;
        }).filter(i => i)).values()).sort((a, b) => a.name.localeCompare(b.name));

        this.students.loading = false;
        this.students.loaded = true;
      })
    },

    assignStudentsClick(students) {
      students.filter(student => !this.isDuplicate(student.id)).forEach(student => {
        if(student.event && student.eventId) {
          student.event.busId = this.selected.bus.id
          if(!student.event.student) {
            student.event.student = new EventStudent(student)
          }
          student.event.status = 'pending'
          this.selected.bus.studentEvents.items.push(student.event)
        }
        else {
          const studentEvent = new StudentEvent({ id: null, studentId: student.id, busId: this.selected.bus.id })
          studentEvent.student = new EventStudent(student)
          studentEvent.status = 'pending'
          this.selected.bus.studentEvents.items.push(studentEvent)

          student.eventId = null
          student.event = studentEvent
        }
      })
    },
    assignStudentsToCapacity(students) {
      const remainingSeats = this.selected.bus.capacity - this.selected.bus.studentEvents.items.length;
      if (remainingSeats <= 0) {
        return;
      }
      const studentsToAssign = students.slice(0, remainingSeats);
      this.assignStudentsClick(studentsToAssign);
    },
    async assignStudentClick(student) {
      if(student) {
        if(student.event && student.eventId) {
          await this.updateStudentEventBusId({ id: student.eventId, busId: this.selected.bus.id}).then(() => {
            student.event.busId = this.selected.bus.id
            if(!student.event.student) {
              student.event.student = new EventStudent(student)
            }
            student.event.status = 'updated'
            this.selected.bus.studentEvents.items.push(student.event)
          })
        }
        else {
          const id = uuid.v4();
          const studentEvent = new StudentEvent({ id: id, studentId: student.id, busId: this.selected.bus.id })
          await createStudentEvent(studentEvent).then(() => {
            if(!studentEvent.student) {
              studentEvent.student = new EventStudent(student)
            }
            studentEvent.status = 'created'
            this.selected.bus.studentEvents.items.push(studentEvent)

            student.eventId = id
            student.event = studentEvent
          })
        }
      }
    },
    async assignStudentEventClick(studentEvent) {
      if(studentEvent) {
        studentEvent.id = uuid.v4();
        await createStudentEvent(new StudentEvent(studentEvent))
        .then(() => {
          const student = this.students.items.find(s => s.id === studentEvent.student?.id)
          if(student && student.event) {
            student.eventId = studentEvent.id
            student.event.id = studentEvent.id
            student.event.status = 'created'
          }
          this.notify({ title: 'Success', text: 'Student Event was successfully updated', icon: this.icon, variant: 'success' });
        })
        .catch(err => {
          console.error(err)
          this.notify({ title: 'Error', text: 'Student Event failed to update', icon: this.icon, variant: 'danger'});
        })
      }
    },
    async unassignStudentEventClick(studentEvent) {
      if(studentEvent) {
        if(studentEvent.id) {
          studentEvent.busId = null
          await this.updateStudentEventBusId({ id: studentEvent.id, busId: studentEvent.busId }).then(() => {
            this.selected.bus.studentEvents.items = this.selected.bus.studentEvents.items.filter(event => event.id !== studentEvent.id)
            const student = this.students.items.find(s => s.id === studentEvent.student?.id)
            if(student && student.event) {
              if(student.event.id === null || student.eventId === null) {
                //if the student being moved back to the unassigned list is missing an event id, then we know we just created it,
                //and it hasn't been saved yet. As we are sending it back we can remove the event all together.
                student.eventId = null
                student.event = null
              }
              else {
                //if the student being moved back to the unassigned list has an event with a valid id, then we should update the event.
                student.event.busId = null
              }
              student.event.status = 'removed'
            }
          })
        }
        else {
          this.selected.bus.studentEvents.items = this.selected.bus.studentEvents.items.filter(event => event.studentId !== studentEvent.studentId)
          const student = this.students.items.find(s => s.id === studentEvent.studentId)
          if(student && student.event) {
            student.eventId = null
            student.event = null
          }
        }
      }
    },
    async unassignStudentEventsClick(studentEvents) {
      this.$refs.layout.state.saving = true
      if(studentEvents.length) {
        await this.batchProcess(studentEvents, async (studentEvent) => {
          if(studentEvent.id) {
            studentEvent.busId = null

            await updateStudentEvent({ id: studentEvent.id, busId: studentEvent.busId }).then(() => {
              this.selected.bus.studentEvents.items = this.selected.bus.studentEvents.items.filter(event => event.id !== studentEvent.id)
              const student = this.students.items.find(s => s.id === studentEvent.studentId)
              if(student && student.event) {
                if(student.event.id === null || student.eventId === null) {
                  //if the student being moved back to the unassigned list is missing an event id, then we know we just created it,
                  //and it hasn't been saved yet. As we are sending it back we can remove the event all together.
                  student.eventId = null
                  student.event = null
                }
                else {
                  //if the student being moved back to the unassigned list has an event with a valid id, then we should update the event.
                  student.event.busId = null
                }
                student.event.status = 'removed'
              }
            })
          }
          else {
            const student = this.students.items.find(s => s.id === studentEvent.studentId)
            if(student && student.event) {
              student.eventId = null
              student.event = null
            }
          }
        }, null, { batchSize: 20, batchDelay: 1000 })
        .then(() => {
          this.selected.bus.studentEvents.items = this.selected.bus.studentEvents.items.filter(event => !studentEvents.map(se => se.id).includes(event.id) )
          this.notify({ title: 'Success', text: 'Student Events were successfully updated', icon: this.icon, variant: 'success' });
        })
        .catch(err => {
          console.error(err)
          this.notify({ title: 'Error', text: 'Student Events failed to update', icon: this.icon, variant: 'danger'});
        })
      }
      this.$refs.layout.state.saving = false
    },
    async saveStudentEvents() {
      this.$refs.layout.state.saving = true
      const { id, studentEvents } = this.selected.bus
      const pending = studentEvents.items.filter(studentEvent => studentEvent.status === 'pending')
      await this.batchProcess(pending, async (studentEvent) => {
        if(studentEvent.id) {
          await updateStudentEvent({ id: studentEvent.id, busId: id })
          .then((updatedStudentEvent) => {
            studentEvent.id = updatedStudentEvent.id
            studentEvent.busId = updatedStudentEvent.busId
            studentEvent.status = 'saved'
          })
        }
        else {
          await createStudentEvent({ id: uuid.v4(), studentId: studentEvent.studentId, busId: id })
          .then((createdStudentEvent) => {
            studentEvent.id = createdStudentEvent.id
            studentEvent.busId = createdStudentEvent.busId
            studentEvent.status = 'saved'
          })
        }
      }, null, { batchSize: 20, batchDelay: 1000 })
      .then(() => {
        this.selected.bus.studentEvents.items = studentEvents.items
        this.notify({ title: 'Success', text: 'Student Events were successfully updated', icon: this.icon, variant: 'success' });
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Student Events failed to update', icon: this.icon, variant: 'danger'});
      })
      .finally(() => {
        this.$refs.layout.state.saving = false
      })
    },
    async updateStudentEventBusId(studentEvent) {
      const { id, busId } = studentEvent
      await updateStudentEvent({ id, busId }).then(event => {
        this.notify({ title: 'Success', text: 'Student Event was successfully updated', icon: this.icon, variant: 'success' });
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Student Event failed to update', icon: this.icon, variant: 'danger'});
      })
    },

    isDuplicate(studentId) {
      return this.duplicateStudents?.some(s => s.id === studentId) ?? false
    },
    openBusRosterModal(modalId) {
      this.$bvModal.show(modalId)
    },
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  },
}
</script>

<style lang="scss">
.list-group:empty,
.list-group > div:empty {
  padding:1rem;
  margin-right: .5rem;
  text-align:left;
  border: 1px dashed rgba(34, 41, 47, 0.125);
}

.ps-buses {
  .list-group:empty:before,
  .list-group > div:empty:before {
    content: 'No Buses Found...';
  }
}
</style>
